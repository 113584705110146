export const countryRegions = {

  "AFRICA": [  "Algeria",  "Angola",  "Benin",  "Botswana",  "Burkina Faso",  "Burundi",  "Cabo Verde",  "Cameroon",  "Central African Republic",  "Chad",  "Comoros",  "Democratic Republic of the Congo",  "Republic of the Congo",  "Cote d'Ivoire",  "Djibouti",  "Egypt",  "Equatorial Guinea",  "Eritrea",  "Eswatini",  "Ethiopia",  "Gabon",  "Gambia",  "Ghana",  "Guinea",  "Guinea-Bissau",  "Kenya",  "Lesotho",  "Liberia",  "Libya",  "Madagascar",  "Malawi",  "Mali",  "Mauritania",  "Mauritius",  "Morocco",  "Mozambique",  "Namibia",  "Niger",  "Nigeria",  "Rwanda",  "Sao Tome and Principe",  "Senegal",  "Seychelles",  "Sierra Leone",  "Somalia",  "South Africa",  "South Sudan",  "Sudan",  "Tanzania",  "Togo",  "Tunisia",  "Uganda",  "Zambia",  "Zimbabwe"],

  "CENTRAL AMERICA": [  "Belize",  "Costa Rica",  "El Salvador",  "Guatemala",  "Honduras",  "Nicaragua",  "Panama"],

  "ASIA": [  "Afghanistan",  "Armenia",  "Azerbaijan",  "Bahrain",  "Bangladesh",  "Bhutan",  "Brunei",  "Cambodia",  "China",  "Cyprus",  "Georgia",  "India",  "Indonesia",  "Iran",  "Iraq",  "Israel",  "Japan",  "Jordan",  "Kazakhstan",  "Kuwait",  "Kyrgyzstan",  "Laos",  "Lebanon",  "Malaysia",  "Maldives",  "Mongolia",  "Myanmar (Burma)",  "Nepal",  "North Korea",  "Oman",  "Pakistan",  "Palestine",  "Philippines",  "Qatar",  "Russia",  "Saudi Arabia",  "Singapore",  "South Korea",  "Sri Lanka",  "Syria",  "Taiwan",  "Tajikistan",  "Thailand",  "Timor-Leste (East Timor)",  "Turkey",  "Turkmenistan",  "United Arab Emirates (UAE)",  "Uzbekistan",  "Vietnam",  "Yemen"],
  
"EUROPE": [
  "Albania", "Andorra", "Austria", "Belarus", "Belgium", "Bosnia and Herzegovina",
  "Bulgaria", "Croatia", "Cyprus", "Czech Republic", "Denmark", "England", "Estonia",
  "Finland", "France", "Germany", "Greece", "Hungary", "Iceland", "Ireland", "Italy",
  "Kosovo", "Latvia", "Liechtenstein", "Lithuania", "Luxembourg", "Malta", "Moldova",
  "Monaco", "Montenegro", "Netherlands", "North Macedonia (formerly Macedonia)", "Northern Ireland",
  "Norway", "Poland", "Portugal", "Romania", "Russia", "San Marino", "Scotland",
  "Serbia", "Slovakia", "Slovenia", "Spain", "Sweden", "Switzerland", "Ukraine",
  "Vatican City (Holy See)", "Wales"
]
,

  "OCEANIA": [  "Australia",  "Fiji",  "Kiribati",  "Marshall Islands",  "Micronesia, Federated States of",  "Nauru",  "New Zealand",  "Palau",  "Papua New Guinea",  "Samoa",  "Solomon Islands",  "Tonga",  "Tuvalu",  "Vanuatu"],

  "NORDICS": [  "Denmark",  "Finland",  "Iceland",  "Norway",  "Sweden"],

  "MIDDLE EAST": [  "Bahrain",  "Cyprus",  "Egypt",  "Iran",  "Iraq",  "Israel",  "Jordan",  "Kuwait",  "Lebanon",  "Oman",  "Palestine",  "Qatar",  "Saudi Arabia",  "Syria",  "Turkey",  "United Arab Emirates (UAE)",  "Yemen"],

  "NORTH AMERICA": [  "Canada",  "Mexico",  "United States"],

  "SOUTH AMERICA": [  "Argentina",  "Bolivia",  "Brazil",  "Chile",  "Colombia",  "Ecuador",  "Guyana",  "Paraguay",  "Peru",  "Suriname",  "Uruguay",  "Venezuela"]

};

const allCountries = Object.values(countryRegions).flatMap(region => region);

// Ordenar alfabéticamente
export const countryList = allCountries.sort((a, b) => a.localeCompare(b));



  export   const usStates = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ]