import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import validator, { isIn } from "validator";
import { navBarContext } from "./context/context";
import { countryRegions, countryList } from "../countries.js";
import { countriesData } from "../countriesData.js";
import PhoneInput from "react-phone-input-2";
import { updateLatestActions, sendBugEmails } from "../utils";
import "react-phone-input-2/lib/style.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebaseApp";
import { MdCheckCircle } from "react-icons/md";
import axios from "axios";
import Unicorn from "../assets/prairiwhite.png";
import Check from "../assets/check.png";
import SilverDot from "../assets/silver-dot.png";
import GreenTick from "../assets/lightergreentick.png";
import Arrow from "../assets/curved-arrow.png";
import {
  Modal,
  PrairiButton,
  BugTab,
  Loading,
} from "@peterfosso/prairi-components";
import {
  collection,
  db,
  getDoc,
  doc,
  query,
  where,
  getDocs,
  createUserWithEmailAndPassword,
  RecaptchaVerifier,
  deleteField,
  auth,
  Timestamp,
  updateDoc,
  addDoc,
  signInWithPhoneNumber,
} from "../firebase/firebaseApp";
import { useMediaQuery } from "react-responsive";
import JoinButton from "./common/JoinButton/JoinButton";
const InvitedRegister = () => {
  const { navBarHeight } = useContext(navBarContext);
  let navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  let { docId } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [verified, setVerified] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [registeredPopup, setRegisteredPopup] = useState(false);
  const [SMSError, setSMSError] = useState("");
  const [region, setRegion] = useState("");
  const [generatedCode, setGeneratedCode] = useState("");
  const [verificationInProgress, setVerificationInProgress] = useState(false);
  const [location, setLocation] = useState({});
  const [detectedCountry, setDetectedCountry] = useState("");
  const [smsPopup, setSmsPopup] = useState(false);
  const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
  const [bugEmail, setBugEmail] = useState("");
  const [bugText, setBugText] = useState(null);
  const [bugError, setBugError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [touchedFields, setTouchedFields] = useState({
    email: false,
    phone: false,
  });
  const [data, setData] = useState({
    email: "",
    error: "",
    loading: false,
    contactName: "",
    message: "",
    code: "",
    phone: "",
    country: "",
    state: "",
    smsConsent: true,
    emailConsent: true,
    contactMethod: "",
    password: "",
    codeError: false,
    codeComplete: false,
    smsError: false,
    emailError: false,
    emailComplete: false,
    contactNameError: false,
    contactNameComplete: false,
    countryError: false,
    countryComplete: false,
    stateError: false,
    stateComplete: false,
    phoneError: false,
    phoneComplete: false,
    passwordError: false,
    passwordComplete: false,
  });

  const [passwordCharacters, setPasswordCharacters] = useState(false);
  const [passwordNumbers, setPasswordNumbers] = useState(false);
  const [passwordSpecial, setPasswordSpecial] = useState(false);
  const [isStartup, setIsStartup] = useState(false);
  const [isInvestor, setIsInvestor] = useState(false);

  const handleUpload = (e) => {
    return new Promise((resolve, reject) => {
      const selectedFile = e.target.files[0];

      const storageRef = ref(storage, `bugs/${selectedFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
        (error) => {
          // Manejador de error
          console.error("Upload failed:", error);
          reject(error);
        },
        async () => {
          // Manejador de éxito
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
          console.log("uploaded correctly");
          setScreenshotUrl(downloadURL);
        }
      );
    });
  };

  const handleBugText = (e) => {
    const value = e.target.value;
    setBugText(value);
    if (value !== "") {
      setBugError("");
    }
  };

  const handleBugEmail = (e) => {
    const value = e.target.value;
    setBugEmail(value);
    if (value !== "") {
      setBugError("");
    }
  };

  const validateFields = () => {
    if (!bugText || bugText.trim() === "") {
      setBugError("Please describe the bug.");
      return false;
    }
    if (!bugEmail || bugEmail.trim() === "") {
      setBugError("We need your email address.");
      return false;
    }
    return true;
  };

  const handleUploadAndSendEmail = async (file) => {
    try {
      if (!validateFields()) return;

      let uploadedUrl = screenshotUrl;
      if (file) {
        uploadedUrl = await handleUpload({ target: { files: [file] } });
      }

      console.log(`screenshotUrl ${uploadedUrl}`);
      sendBugEmails(
        bugEmail,
        "",
        bugText,
        "InvitedRegister",
        uploadedUrl || ""
      );
    } catch (error) {
      console.error("Error uploading file or sending email:", error);
    }
  };

  const storeView = async (userId) => {
    const userRef = doc(db, "preUsers", userId); //We'll concat or something. But we're storing latest actions before registering in preUsers as well.
    updateLatestActions(userRef, "Register form view", isMobile);
    await updateDoc(userRef, { accessedRegisterForm: true });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    storeView(docId);
  }, []);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await axios.get(
          "https://ipinfo.io/json?token=00b36ccb7a5f35"
        );
        const countryCode = response.data.country.toLowerCase();
        setLocation(countryCode);
        const detectedCountry =
          countriesData.find(
            (country) => country.code.toLowerCase() === countryCode
          )?.name || "";
        setDetectedCountry(detectedCountry);
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    };

    fetchLocation();
  }, []); // Empty dependency array to ensure the effect runs only once on component mount

  const handleBlur = (field) => {
    setTouchedFields((prev) => ({ ...prev, [field]: true }));
  };

  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        const docRef = doc(db, "preUsers", docId);
        const docData = await getDoc(docRef);
        if (docData.exists()) {
          setData((prevData) => ({
            ...prevData,
            ...docData.data(),
            country: detectedCountry || "", // Si country no existe en la data, usa detectedCountry
          }));
          setIsStartup(docData.data().category === "startup");
          setIsInvestor(docData.data().category === "investor");
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchDocumentData();
  }, [docId, detectedCountry]);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const getId = async () => {
    // get code and email from docId params
    if (docId && isNaN(Number(docId))) {
      try {
        const docRef = doc(db, "preUsers", docId);

        const docSnap = await getDoc(docRef);
        const {
          username: name,
          email,
          category,
          registered,
          seeking,
        } = docSnap.data();
        if (registered) {
          setRegisteredPopup(true);
          return;
        } else {
          setData({
            ...data,
            email,
            seeking,
            category,
            emailComplete: email !== "",
            codeComplete: true,
            contactName: name,
            contactNameComplete: true,
            countryComplete: true,
          });
        }
      } catch (error) {
        setData({
          ...data,
        });
      }
    } else {
      setData({ ...data, code: docId, codeComplete: true });
    }
  };

  useEffect(() => {
    getId();
    //console.log(location);
  }, [docId]);

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setData({
        ...data,
        [e.target.name]: e.target.checked,
      });
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }
  };

  const updateFieldValidity = (bool, fieldName) => {
    if (bool) {
      setData({
        ...data,
        [`${fieldName}Error`]: true,
        [`${fieldName}Complete`]: false,
      });
      console.log(` error true, complete false${bool}  ${fieldName}`);
    } else {
      setData({
        ...data,
        [`${fieldName}Error`]: false,
        [`${fieldName}Complete`]: true,
      });
      console.log(` error false, complete true${bool}  ${fieldName}`);
    }
  };
  const isValid = (fieldName) => {
    switch (fieldName) {
      case "email":
        updateFieldValidity(!validator.isEmail(email), fieldName);
        break;
      case "contactName":
        updateFieldValidity(validator.isEmpty(contactName), fieldName);
        break;
      case "phone":
        updateFieldValidity(validator.isEmpty(phone), fieldName);
        break;
      case "password":
        updateFieldValidity(validator.isEmpty(password), fieldName);
        break;
      default:
        break;
    }
  };

  const isFinallyValid = () => {
    let dataCopy = { ...data };
    if (!smsConsent) {
      dataCopy = {
        ...dataCopy,
        smsError: true,
      };
    }
    if (country === "" && data.category === "investor") {
      dataCopy = {
        ...dataCopy,
        countryError: true,
        countryComplete: false,
        error: "Fill all the required fields",
      };
    }
    if (!validator.isEmail(email)) {
      dataCopy = {
        ...dataCopy,
        emailError: true,
        emailComplete: false,
        error: "Please enter a valid email address",
      };
    }
    if (validator.isEmpty(contactName)) {
      dataCopy = {
        ...dataCopy,
        contactNameError: true,
        contactNameComplete: false,
        error: "Fill all the required fields",
      };
    }
    if (validator.isEmpty(phone)) {
      dataCopy = {
        ...dataCopy,
        phoneError: true,
        phoneComplete: false,
        error: "Please enter a valid phone number",
      };
    }
    if (validator.isEmpty(country) && data.category === "investor") {
      dataCopy = {
        ...dataCopy,
        countryError: true,
        countryComplete: false,
        error: "",
      };
    }
    // if (validator.isEmpty(state) && data.category==="investor" && country==="United States") {
    //   dataCopy = {
    //     ...dataCopy,
    //     stateError: true,
    //     stateComplete: false,
    //     error: "Fill all the required fields",
    //   };
    // }
    if (
      validator.isEmpty(password) ||
      password.length < 8 ||
      !/\d/.test(password) ||
      !/[!@#$%^&*(),.?":{}|<>]/.test(password)
    ) {
      dataCopy = {
        ...dataCopy,
        passwordError: true,
        passwordComplete: false,
        error:
          "Password must be at least 8 characters long and include at least one number and one special character",
      };
    }
    setData({ ...dataCopy });
    // override global variables
    const {
      codeError,
      emailError,
      contactNameError,
      phoneError,
      smsError,
      countryError,
      stateError,
      passwordError,
    } = dataCopy;
    return codeError ||
      emailError ||
      contactNameError ||
      phoneError ||
      passwordError ||
      countryError ||
      stateError ||
      smsError
      ? false
      : true;
  };

  const generateCode = () => {
    const randomNumber = Math.floor(100000 + Math.random() * 900000);
    const sixDigitCode = randomNumber.toString();
    return sixDigitCode;
  };

  const sendCode = async (phone, SMSCode) => {
    const messagesCollection = collection(db, "messages");
    const dataToStore = {
      to: `+${phone}`,
      body: `PRAIRI: Your confirmation code is ${SMSCode}`,
    };

    await addDoc(messagesCollection, dataToStore);
  };

  const generateAndSendCode = (phone) => {
    const SMSCode = generateCode();
    //console.log("SMS Code:", SMSCode);
    setGeneratedCode(SMSCode);
    sendCode(phone, SMSCode);
  };

  const handleVerification = async () => {
    return new Promise((resolve, reject) => {
      try {
        if (generatedCode === verificationCode && !verificationInProgress) {
          //console.log("verified?" + JSON.stringify(verified));
          resolve(); // Resolve the promise when verification is successful
        } else {
          setSMSError("Wrong code, please try again.");
          reject(new Error("Verification failed")); // Reject the promise when verification fails
        }
      } catch (error) {
        console.error("Code verification error:", error);
        setSMSError("An error occurred while verifying the code.");
        reject(error); // Reject the promise on error
      }
    });
  };

  const handleUserVerification = async () => {
    try {
      await handleVerification();
      setVerified(true);
      setShowVerify(false);
      // Additional logic if needed after user triggers verification
    } catch (error) {
      // Handle errors if necessary
      console.error("User verification error:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFinallyValid()) {
      return;
    }

    try {
      setData({
        ...data,
        error: "",
        loading: true,
        message: "Please wait...",
      });

      // Wait for the user to enter and submit the verification code
      if (isFinallyValid()) {
        try {
          // check if invitation code was provided to this email

          let userRef = doc(db, "preUsers", docId);
          let userDoc = await getDoc(userRef);
          let userData = userDoc.data();

          if (!userData.registered) {
            setData({
              ...data,
              error: "",
              loading: true,
              message: "Saving and proceeding to Step 2.",
            });
            const result = await createUserWithEmailAndPassword(
              auth,
              email,
              password
            );
            const emailLowerCase = email.toLowerCase();
            const time = Timestamp.fromDate(new Date());

            // Update the existing document in the 'users' collection
            let existingUserId = docId;

            if (existingUserId) {
              let existingUserRef = doc(db, "users", existingUserId);
              await updateDoc(existingUserRef, {
                uid: result.user.uid,
                contactName,
                phone,
                country,
                state,
                email: emailLowerCase,
                smsConsent,
                registeredAt: time,
                emailConsent,
                registered: true,
                paused: false,
              });
            } else {
              throw new Error("User document not found in 'users' collection.");
            }

            // Remove all fields except 'email' from preUsers document and set 'registered' to true
            await updateDoc(userRef, {
              email: emailLowerCase,
              registered: true,
              // category: deleteField(),
              // username: deleteField(),
              phone: deleteField(),
              state: deleteField(),
              smsConsent: deleteField(),
              createdAt: deleteField(),
              payCode: deleteField(),
              paymentPending: deleteField(),
              //seeking: deleteField(),
            });

            updateLatestActions(userRef, "Registered", isMobile);
            if (seeking === "NOT SEEKING") {
              navigate("/");
            } else {
              navigate(
                `/${
                  userData.category === "startup"
                    ? "startupregistration"
                    : "investorexpress"
                }`
              );
            }

            setData({
              ...data,
              loading: false,
            });
          } else {
            throw new Error("You are already registered. Sign in instead!");
          }
        } catch (err) {
          setData({
            ...data,
            error: err.message,
            loading: false,
            message: "",
          });
        }
      }
    } catch (err) {
      setData({
        ...data,
        error: err.message,
        loading: false,
        message: "",
      });
    }
  };

  const {
    email,
    error,
    message,
    contactName,
    phone,
    state,
    country,
    password,
    smsConsent,
    emailConsent,
    seeking,
    emailError,
    smsError,
    contactNameError,
    phoneError,
    passwordError,
    emailComplete,
    contactNameComplete,
    countryError,
    countryComplete,
    phoneComplete,
    passwordComplete,
  } = data;

  useEffect(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^\+?[1-9]\d{3,14}$/;

    setData((prevData) => ({
      ...prevData,
      contactNameError: touchedFields.contactName
        ? !contactName.length > 0
        : false,
      emailError: touchedFields.email
        ? !emailRegex.test(prevData.email)
        : false, // No muestra error hasta que el usuario toque el campo
      phoneError: touchedFields.phone
        ? !phoneRegex.test(prevData.phone)
        : false,
      countryError: touchedFields.country
        ? country === "" && data.category === "investor"
        : false,
      passwordError: touchedFields.password
        ? password.length < 8 ||
          !/\d/.test(password) ||
          !/[!@#$%^&*(),.?":{}|<>]/.test(password)
        : false,
    }));
    if (password && password.length > 0) {
      setPasswordCharacters(password.length >= 8);
      setPasswordNumbers(/\d/.test(password));
      setPasswordSpecial(/[!@#$%^&*(),.?":{}|<>]/.test(password));
    } else {
      setPasswordCharacters(false);
      setPasswordNumbers(false);
      setPasswordSpecial(false);
    }
  }, [email, phone, touchedFields, contactName, country, password]);

  if (isLoading) {
    return (
      <div style={{ marginTop: navBarHeight + 12 }}>
        <Loading />
      </div>
    );
  } else {
    return (
      <div
        className={`${isMobile&&"flex-col"} flex justify-between`}
        style={{ marginTop: navBarHeight + 4 }}

      >
        <div className={`text-[#f2f2f2] ${isMobile?"w-full":"w-1/2 pl-12"} p-5 flex flex-col  LEFT`}>
          {
            <div className="mx-2 mt-2 pt-8 px-8 text-[3vh]">
              <div className="mb-2 text-[4vh]">
                {contactName.split(" ")[0]},{" "}
              </div>
              You've been invited to join PRAIRI at{" "}
              <span className="underline">no cost</span> <br />
              (save {isStartup ? "$149" : "$900/year!"})
            </div>
          }
          {!isMobile && <div className="w-full flex items-end justify-end">
            <img
              alt="Arrow pointing right"
              src={Arrow}
              className="w-1/2 pl-12"
            />
          </div>}
          <div className="text-bluePrairi text-[5vh] mb-4 font-bold pl-10">
            {isInvestor ? (
              <div>
                Unlock high-potential Startups
                <br />
                faster & beyond your network.
              </div>
            ) : (
              <div>
                Match with investors <br /> looking for a startup like yours
              </div>
            )}
          </div>
          {isInvestor && <div className="text-lg">
          <div className="pl-10 flex items-center mb-1">
            <img alt="checkmark" src={Check} className="mr-4 h-[14px]" />
            <div>
              Unlock curated startups matches - locally and{" "}
              <span className="font-bold">globally</span>
            </div>
          </div>
          <div className="pl-10 flex items-center mb-1">
            <img alt="checkmark" src={Check} className="mr-4 h-[14px]" />
            <div>
              Receive alerts for new Startups that match your criteria
            </div>
          </div>
          <div className="pl-10 flex items-center mb-1">
            <img alt="checkmark" src={Check} className="mr-4 h-[14px]" />
            <div>
              Access to all current criteria filters (basic & advanced)
            </div>
          </div>
          <div className="pl-10 flex items-center mb-1">
            <img alt="checkmark" src={Check} className="mr-4 h-[14px]" />
            <div>
              Track your deal progress
            </div>
          </div>
          <div className="pl-10 flex items-center mb-1">
            <img alt="checkmark" src={Check} className="mr-4 h-[14px]" />
            <div>
              Maintain your anonymity while reviewing profiles
            </div>
          </div>
          </div>}
          {isStartup && <div className="text-lg">
          <div className="pl-10 flex items-center mb-1">
            <img alt="checkmark" src={Check} className="mr-4 h-[14px]" />
            <div>
            Be seen by local and global investors
            </div>
          </div>
          <div className="pl-10 flex items-center mb-1">
            <img alt="checkmark" src={Check} className="mr-4 h-[14px]" />
            <div>
            Get noticed by the right investors
            </div>
          </div>
          <div className="pl-10 flex items-center mb-1">
            <img alt="checkmark" src={Check} className="mr-4 h-[14px]" />
            <div>
            Match with investors – then they contact YOU
            </div>
          </div>
          <div className="pl-10 flex items-center mb-1">
            <img alt="checkmark" src={Check} className="mr-4 h-[14px]" />
            <div>
            Track your deal progress
            </div>
          </div>
          <div className="pl-10 flex items-center mb-1">
            <img alt="checkmark" src={Check} className="mr-4 h-[14px]" />
            <div>
            Less Chasing. More Building
            </div>
          </div>
          </div>}
          {isInvestor && <div className="text-[1.2vh] pl-10 mt-10">
            Lifetime Access strictly refers to our service of matching startups with investors, at the time(s) startups are seeking funding. Future services may be offered at an additional cost, should you choose to use them. How many matches you receive depends on your criteria.
          </div>}
          {isStartup && <div className="text-[1.2vh] pl-10 mt-10">
            Lifetime Access strictly refers to our service of matching startups with investors, at the time(s) startups are seeking funding. Future services may be offered at an additional cost, should you choose to use them. How many matches you receive depends on your criteria.
          </div>}
        </div>
        <div
          className={`  border-[0.5px] rounded-[5%] ${isMobile?"w-[90%]":"w-1/3"} flex flex-col justify-center items-center  px-8 pt-4 m-8 mt-0  border-[#f2f2f2] border-solid ${isStartup && "mt-4"}`}
        >
          <BugTab
            email={bugEmail}
            setEmail={handleBugEmail}
            setText={handleBugText}
            submit={(file) => handleUploadAndSendEmail(file)}
            error={bugError}
            handleFileChange={handleUpload}
          />

          {isInvestor && (
            <div>
              <h1 className="mb-4 text-xl font-bold text-center text-base text-white">
                Join PRAIRI as an Investor
              </h1>
            </div>
          )}
                    {isStartup && (
            <div>
              <h1 className="mb-4 text-xl font-bold text-center text-base text-white">
                Join PRAIRI as a Startup
              </h1>
            </div>
          )}
          <div className="pl-4">
            <div className="line-through decoration-red-500 text-xl">{isInvestor?"$900/year":"$149/year"}</div>
            <div><span className="text-bluePrairi text-xl">You pay: $0</span> for <span className="font-bold">Lifetime Access</span></div>
          </div>

          <form className=" py-0 px-5" onSubmit={handleSubmit}>
            <div className="mt-5 relative"></div>
            <div className="mt-5 relative text-sm">
              <label htmlFor="contactName">Contact Name</label>
              <div className="flex items-center">
                <input
                  className={`${
                    contactNameError ? "border-red-500" : "border-blackish"
                  } mt-1 w-10/12 md:w-11/12 rounded border-2 p-1.5 text-black appearance-none outline-none`}
                  type="text"
                  name="contactName"
                  id="contactName"
                  placeholder="Your name"
                  value={contactName}
                  onChange={handleChange}
                  onBlur={() => handleBlur("contactName")}
                ></input>
                {!contactNameError && contactName !== "" && (
                  <div className=" text-4xl ml-2 text-green-500 ">
                    <MdCheckCircle color="#92d050" />
                  </div>
                )}
              </div>
              {contactNameError && (
                <div className="w-full text-sm text-red-500 mt-2">
                  Contact name is required
                </div>
              )}
            </div>
            <div className="mt-5 relative text-sm">
              <label htmlFor="email">Email Address</label>
              <div className="flex items-center">
                <input
                  className={`${
                    emailError ? "border-red-500" : "border-blackish"
                  } mt-1 w-10/12 md:w-11/12 rounded border-2 p-1.5 text-black appearance-none outline-none`}
                  type="email"
                  name="email"
                  id="email"
                  placeholder="mail@company.com"
                  value={email}
                  onChange={handleChange}
                  onBlur={() => handleBlur("email")}
                ></input>
                {!emailError && email !== "" && (
                  <div className="ml-2 text-4xl  text-green-500 ">
                    <MdCheckCircle color="#92d050" />
                  </div>
                )}
              </div>
              {emailError && (
                <div className="w-full text-sm text-red-500 mt-2">
                  Provide a valid email
                </div>
              )}
            </div>
            {isInvestor && (
              <div className="mt-5 text-sm">
                <div>
                  <label htmlFor="country">
                    Location of investor/company (country where you are
                    officially headquartered)
                  </label>

                  <div className="flex items-center">
                    <select
                      className={`${
                        countryError ? "border-red-500" : "border-blackish"
                      } mt-1 w-10/12 md:w-11/12 rounded border-2 p-1.5 text-black outline-none`}
                      value={country}
                      onChange={handleChange}
                      name="country"
                      id="country"
                      onBlur={() => handleBlur("country")}
                    >
                      <option value="">Select a country</option>
                      {countryList.map((option, index) => (
                        <option key={option.value}>{option}</option>
                      ))}
                    </select>

                    {country !== "" && (
                      <div className="text-4xl ml-2 text-green-500 ">
                        <MdCheckCircle color="#92d050" />
                      </div>
                    )}
                  </div>

                  {countryError && (
                    <div className="w-full text-sm text-red-500 mt-2">
                      Select your location
                    </div>
                  )}
                  {/* {country === "United States" && (
              <div className="flex items-center">
                <select
                  className={`${
                    countryError ? "border-red-500" : "border-blackish"
                  } mt-2.5 w-10/12 md:w-11/12 rounded border-2 p-2.5 text-black outline-none`}
                  value={state}
                  onChange={handleChange}
                  name="state"
                  id="state"
                >
                  <option value="">Select a state</option>
                  {usStates.map((option, index) => (
                    <option key={option.value}>{option}</option>
                  ))}
                </select>
              </div>
            )} */}
                </div>
              </div>
            )}

            <div className="mt-5 relative text-sm">
              <label htmlFor="phone">Phone</label>
              <div className="flex items-center">
                <PhoneInput
                  country={location}
                  value={phone}
                  onChange={(phone) => setData({ ...data, phone })}
                  onBlur={() => handleBlur("phone")}
                  inputProps={{
                    id: "phone",
                  }}
                  countryCodeEditable={false}
                  enableLongNumbers={true}
                  inputStyle={{
                    color: "black",
                    borderColor: `${phoneError ? "red" : "#333"}`,
                    marginTop: "0.625rem",
                    width: "83%",
                    borderRadius: "0.25rem",
                    borderWidth: "1px",
                    fontSize: "1rem",
                    paddingTop: "0.625rem",
                    paddingBottom: "0.625rem",
                    paddingRight: "2rem",
                    outline: "none",
                  }}
                  dropdownStyle={{ color: "black" }}
                />
                {!phoneError && phone !== "" && (
                  <div className=" text-4xl ml-3 text-green-500 ">
                    <MdCheckCircle color="#92d050" />
                  </div>
                )}
              </div>
              {phoneError && (
                <div className="w-full text-sm text-red-500 mt-2">
                  Provide a valid phone number
                </div>
              )}
            </div>
            <div>
              <p className="text-[1.3vh] mt-2 italic">
                PRAIRI sends notifications via SMS, so that you can immediately
                know when there’s a match. (You can always opt out later, in
                ‘Settings’, if you wish.)
              </p>
              <div className="flex">
                <div className="flex mt-[6px] items-center text-sm">
                  <input
                    className="mr-2"
                    type="checkbox"
                    id="agree"
                    name="smsConsent"
                    checked={smsConsent}
                    onChange={handleChange}
                  />
                  <label for="no-traction"> I agree</label>
                </div>
                {smsError && (
                  <div className="w-[200px] ml-2 border solid bg-black text-xs  p-0.5 rounded-md ">
                    PRAIRI requires this. If you have unique circumstances{" "}
                    <span
                      className="cursor-pointer underline text-silverPrairi"
                      onClick={() => navigate("/partner")}
                    >
                      contact us
                    </span>
                    .
                  </div>
                )}
              </div>
            </div>
            <div className="mt-5 relative text-sm">
              <label htmlFor="password">Password</label>
              <div
                className={`flex items-center justify-between ${
                  passwordError ? "border-red-500" : "border-blackish"
                } bg-white mt-1 w-10/12 md:w-11/12 rounded border p-1.5 text-black `}
              >
                <input
                  className=" outline-none w-10/12 md:w-11/12"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="At least 8 characters, one number and one special character"
                  value={password}
                  onChange={handleChange}
                  onBlur={() => handleBlur("password")}
                  autoComplete="on"
                ></input>
                <div className="text-2xl">
                  {showPassword ? (
                    <AiFillEye onClick={togglePassword} />
                  ) : (
                    <AiFillEyeInvisible onClick={togglePassword} />
                  )}
                </div>
                {passwordComplete && !passwordError && (
                  <div className="absolute text-4xl top-1/2 right-0 text-green-500 ">
                    <MdCheckCircle color="#92d050" />
                  </div>
                )}
              </div>
            </div>
            <div className="text-[12px] my-2 italic">
              <div className="flex items-center">
                <img
                  alt="bullet"
                  src={passwordCharacters ? GreenTick : SilverDot}
                  className={`${
                    passwordCharacters ? "w-[15px]" : "w-[10px]"
                  }  mr-2`}
                />
                <div>At least 8 characters</div>
              </div>
              <div className="flex items-center">
                <img
                  alt="bullet"
                  src={passwordNumbers ? GreenTick : SilverDot}
                  className={`${
                    passwordNumbers ? "w-[15px]" : "w-[10px]"
                  }  mr-2`}
                />
                <div>At least one number</div>
              </div>
              <div className="flex items-center">
                <img
                  alt="bullet"
                  src={passwordSpecial ? GreenTick : SilverDot}
                  className={`${
                    passwordSpecial ? "w-[15px]" : "w-[10px]"
                  }  mr-2`}
                />
                <div>At least one special character</div>
              </div>
            </div>
            {passwordError && (
              <div className="text-sm text-red-500">
                Password must be at least 8 characters long and include at least
                one number and one special character
              </div>
            )}

            {error && (
              <p className=" text-sm md:text-base my-2 text-red-500"></p>
            )}
            {message && (
              <p className="text-sm md:text-base my-2  text-green-500">
                {message}
              </p>
            )}
            <div className=" mt-2 mx-0 text-center justify-center items-center flex">
              <JoinButton value="JOIN PRAIRI" />
            </div>
            <Modal open={registeredPopup} border="no-border">
              <div>
                <h1 className="mb-4 text-center text-xl text-gray-500 uppercase">
                  Log in
                </h1>
                <p className="mb-5">Welcome Back</p>
                <p className="mb-5">We see you've already registered...</p>
                <p>- So just log in.</p>
                <p>- Complete your Data Profile</p>
                <p>- then start matching!</p>
                <div className="flex justify-center items-center mt-5">
                  {" "}
                  <PrairiButton
                    label="OK"
                    click={() => navigate("/login")}
                    size="sm"
                    border="green"
                    rounded="soft"
                    bgColor="regular"
                  />
                </div>
              </div>
            </Modal>

            <div id="recaptcha-container"></div>
          </form>
        </div>
      </div>
    );
  }
};

export default InvitedRegister;
