import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NextButton from "../common/NextButton/NextButton";
import axios from "axios";
import { dataContext, authContext } from "../context/context";
import { withStyles } from "@material-ui/core/styles";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
} from "@material-ui/core";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useMediaQuery } from "react-responsive";
import { updateLatestActions } from "../../utils";
import {
  query,
  collection,
  db,
  where,
  getDocs,
  getDoc,
  Timestamp,
  doc,
  updateDoc,
} from "../../firebase/firebaseApp";
import NoPoverty from "../../assets/SDG/E-WEB-Goal-01.png";
import ZeroHunger from "../../assets/SDG/E-WEB-Goal-02.png";
import GoodHealth from "../../assets/SDG/E-WEB-Goal-03.png";
import QualityEducation from "../../assets/SDG/E-WEB-Goal-04.png";
import GenderEquality from "../../assets/SDG/E-WEB-Goal-05.png";
import CleanWater from "../../assets/SDG/E-WEB-Goal-06.png";
import CleanEnergy from "../../assets/SDG/E-WEB-Goal-07.png";
import DecentWork from "../../assets/SDG/E-WEB-Goal-08.png";
import Innovation from "../../assets/SDG/E-WEB-Goal-09.png";
import ReducedInequalities from "../../assets/SDG/E-WEB-Goal-10.png";
import SustainableCities from "../../assets/SDG/E-WEB-Goal-11.png";
import ResponsibleConsumption from "../../assets/SDG/E-WEB-Goal-12.png";
import ClimateAction from "../../assets/SDG/E-WEB-Goal-13.png";
import LifeBelowWater from "../../assets/SDG/E-WEB-Goal-14.png";
import LifeOnLand from "../../assets/SDG/E-WEB-Goal-15.png";
import Peace from "../../assets/SDG/E-WEB-Goal-16.png";
import Partnerships from "../../assets/SDG/E-WEB-Goal-17.png";
import SDG from "../../assets/SDG/SDGs-transparent.png";
import { storage } from "../../firebase/firebaseApp";
import { countryList } from "../../countries.js";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  ImageGridItem,
  Bullet,
  Modal,
  TextButton,
  PrairiButton,
  DeckPrompt,
  UnicornNote,
} from "@peterfosso/prairi-components";
import { CountryDropdown } from "react-country-region-selector";
import Switch from "react-switch";
import CompanyLogo from "../../assets/company2.png";
import PitchLogo from "../../assets/pitch.png";
import Clock from "../../assets/clock4.png";
import Invitation from "../Invitation";
import DeckApproval from "../DeckApproval";
const Company = (props) => {
  const { setDataFunc, globalData } = useContext(dataContext);
  const companyData = globalData.formData.company;
  const { currentUser } = useContext(authContext);
  const [showGoals, setShowGoals] = useState(false);
  const [fileName, setFileName] = useState("");
  const [uploadingDeck, setUploadingDeck] = useState(false);
  const [pitchFile, setPitchFile] = useState(null);
  const [pitchProgress, setPitchProgress] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [updatePending, setUpdatePending] = useState(false);
  const [thankyouNote, setThankyouNote] = useState(false);
  const [showGoalsPopup, setShowgoalsPopup] = useState(
    companyData.goals && companyData.goals.length > 0 && companyData.goals
  );

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const handleGoalsBoolean = (event) => {
    setData({
      ...data,
      goalsBool: event.target.value,
    });
  };

  const goalsHandler = (value) => {
    setData({
      ...data,
      goals: goals.includes(value)
        ? goals.filter((goal) => goal !== value)
        : [...goals, value],
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPitchFile(file);
    const truncatedName =
      file.name.length > 20 ? file.name.substring(0, 20) + "..." : file.name;
    setFileName(truncatedName.length > 0 ? truncatedName : "");
    setDeckError("");
  };

  const WhiteRadio = withStyles({
    root: {
      color: "white",
      "&$checked": {
        color: "green",
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  useEffect(() => {
    if (goalsBool === "Yes") {
      setShowgoalsPopup(true);
    }
  }, [showGoals]);
  const [limitMessage, setLimitMessage] = useState(null);
  const [deckError, setDeckError] = useState("");
  const [pitchUpdatePopup, setPitchUpdatePopup] = useState(false);
  const [docId, setDocId] = useState(null);
  const [currentUserData, setCurrentUserData] = useState({});
  const [pendingDeck, setPendingDeck] = useState(false);
  const [outsideDeck, setOutsideDeck] = useState(false);
  const [data, setData] = useState({
    company: companyData.company || "",
    country: companyData.country || "",
    state: "",
    date: companyData.date || "",
    slogan: companyData.slogan || "",
    deck: companyData.deck || globalData.pitchdeck || "",
    url: companyData.url || globalData.url || "",
    pitch: companyData.pitch || "",
    videoPitch: companyData.videoPitch || "",
    investorButton: companyData.investorButton || "",
    generatedFields: companyData.generatedFields || "",
    goals: companyData.goals || [],
    goalsBool: companyData.goalsBool || false,
    pendingApproval: globalData.pendingApproval,
    error: "",
    loading: false,
    message: "",
  });
  const deckPattern =
    /^https:\/\/firebasestorage\.googleapis\.com\/v0\/b\/prairi-prod-fe3a4\.appspot\.com\/.*/;
  const testUrl = (url) => deckPattern.test(url);
  let navigate = useNavigate();
  const usStates = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const getDataFromDb = async () => {
    try {
      // Get user data from email
      const { email } = currentUser;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
        setCurrentUserData(doc.data());
        setUpdatePending(
          userData.formData &&
            !testUrl(userData.formData.company.deck) &&
            !doc.data().pendingPitchDeck
        );
        if (!doc.data().formData?.company?.deck && doc.data().pitchdeck) {
          companyData.deck = doc.data().pitchdeck;
          if (!testUrl(deck)) {
            setOutsideDeck(true);
          }
          setData((prevState) => ({
            ...prevState,
            deck: doc.data().pitchdeck,
          }));
        }
        if (doc.data().pendingPitchDeck) {
          setPendingDeck(true);
        }
        if (doc.data().url && !doc.data().formData) {
          companyData.url = doc.data().url;
          setData((prevState) => ({
            ...prevState,
            url: doc.data().url,
          }));
        }
      });
      return { userData, userId };
    } catch (error) {
      //  console.log(error);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "pitch" && e.target.value.length >= 500) {
      setLimitMessage("Your pitch must be 500 characters or less.");
    } else {
      setLimitMessage(null);
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const storeFormView = async (e) => {
    try {
      const { userData, userId } = await getDataFromDb();
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userEmail = userSnap.data().email;
        const preUsersRef = collection(db, "preUsers");
        const q = query(preUsersRef, where("email", "==", userEmail));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const preUserDoc = querySnapshot.docs[0];
          const preUserRef = doc(db, "preUsers", preUserDoc.id);
          if (!preUserDoc.data().formFirstView) {
            await updateDoc(preUserRef, { formFirstView: true });
            //updateLatestActions(preUserRef, "Forms first view", isMobile);
          } else {
            return;
          }
        } else {
          console.log("No matching preUser document found.");
        }
      } else {
        console.log("User document does not exist.");
      }
    } catch (error) {
      console.log("Error updating document:", error);
    }
  };

  useEffect(() => {
    const getUpdatedData = () => {
      setData((prevData) => ({ ...prevData, ...companyData }));
    };
    getUpdatedData();
  }, [companyData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    storeFormView();
  }, []);

  useEffect(() => {
    if (currentUser) {
      const fetchData = async () => {
        const { userData, userId } = await getDataFromDb();
        setDocId(userId);
      };
      fetchData();
    }
  }, [currentUser, pendingDeck, outsideDeck]);

  const addField = (groupName) => {
    // all fields
    const allFields = generatedFields[groupName];
    const newFieldName = `field${allFields.length}`;
    const newField = { fieldName: newFieldName, fieldValue: "" };
    allFields.push(newField);
    setData({
      ...data,
      generatedFields: {
        ...data.generatedFields,
        [groupName]: [...allFields],
      },
    });
  };

  const handleDynamicInput = (e, groupName, index) => {
    const allFields = generatedFields[groupName];
    allFields[index].fieldValue = e.target.value;
    setData({
      ...data,
      generatedFields: {
        ...data.generatedFields,
        [groupName]: [...allFields],
      },
    });
  };
  const closeField = (groupName, index) => {
    const allFields = generatedFields[groupName];
    allFields.splice(index, 1);
    setData({
      ...data,
      generatedFields: {
        ...data.generatedFields,
        [groupName]: [...allFields],
      },
    });
  };

  const handleUpload = () => {
    return new Promise((resolve, reject) => {
      if (!pitchFile) {
        setDeckError("No file selected");
        reject("No file selected");
        return;
      }

      setUploadingDeck(true);
      const storageRef = ref(storage, `pdfs/${pitchFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, pitchFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPitchProgress(progress);
        },
        (error) => {
          console.error("Upload failed:", error);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setPdfUrl(downloadURL);
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const handleDeckEmail = async (pdfUrl) => {
    const functions = getFunctions();
    const sendEmailFn = httpsCallable(functions, "sendEmail");
    await sendEmailFn({
      templateId: 54,
      recipients: [
        {
          name: "Carolina",
          email: "kro.ruz@gmail.com",
        },
        {
          name: "Peter",
          email: "peter@prairi.com",
        },
        {
          name: "Peter",
          email: "pfosso@gmail.com",
        },
      ],
      params: {
        MESSAGE: `${companyData.company || company} ${
          deck !== ""
            ? "needs to update its pitch deck."
            : "has uploaded its pitch deck for the first time."
        }`,
        NEWDECK: `http://www.prairi.com/newdeck/${docId}`,
        COMPANY: companyData.company || company,
      },
      sujbect:  `${companyData.company || company} ${
        deck !== ""
          ? "needs to update its pitch deck."
          : "has uploaded its pitch deck for the first time."
      }`
    });
  };

  const handleSubmitDeck = async () => {
    try {
      let userRef = doc(db, "users", docId);
      const pdfUrl = await handleUpload();
      console.log(data);
      await updateDoc(userRef, {
        pendingPitchDeck: pdfUrl,
      });
      handleUpload();
      updateLatestActions(userRef, "Submitted new deck for approval", isMobile);
      handleDeckEmail(pdfUrl);
      setUploadingDeck(false);
      setThankyouNote(true);
      setPitchUpdatePopup(false);
    } catch {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const companyName = company.trim();
    const companyCountry = country.trim();
    const companySlogan = slogan.trim();
    const companyPitch = pitch.trim();
    const companyVideoPitch = videoPitch.trim();
    const companyState = state.trim();
    const companyDeck = deck.trim();
    const time = Timestamp.fromDate(new Date());
    const latestTime = time
      .toDate()
      .toLocaleDateString("en-US", { month: "2-digit", day: "2-digit" });
    if (
      !companyName ||
      !companyCountry ||
      !companySlogan ||
      !companyPitch ||
      !(companyDeck || fileName || globalData.pendingPitchDeck) ||
      (companyCountry === "United States" && !companyState)
    ) {
      setData({ ...data, error: "Fill all the required fields." });
    } else {
      setData({
        ...data,
        error: "",
        loading: true,
        message: "Saving... (click progress bar if you wish to edit)",
      });

      setTimeout(() => {
        setDataFunc({
          ...globalData,
          formData: {
            ...globalData.formData,
            company: {
              ...companyData,
              company: companyName,
              country: companyCountry,
              state: companyState,
              date,
              slogan,
              deck,
              pitch,
              url,
              videoPitch,
              generatedFields,
              investorButton,
              goals,
              goalsBool,
              completed: true,
            },
          },
        });
        if (fileName && deck === "") {
          handleSubmitDeck();
        }
        navigate("/startupregistration/industry");
        let ref = doc(db, "users", docId);
        updateLatestActions(ref, "Saved company form", isMobile);
      }, 2000);
    }
  };
  const {
    company,
    country,
    state,
    date,
    videoPitch,
    slogan,
    deck,
    url,
    pendingApproval,
    pitch,
    generatedFields,
    investorButton,
    message,
    error,
    goals,
    goalsBool,
  } = data;

  useEffect(() => {
    if (
      company !== "" &&
      url !== "" &&
      country !== "" &&
      slogan !== "" &&
      pitch !== ""
    ) {
      setData((prevData) => ({
        ...prevData,
        error: false,
      }));
    }
  }, [company, url, country, slogan, pitch]);

  return (
    <div className=" shadow-black w-[90%] rounded-md mt-2 md:max-w-xl mx-auto py-2.5   ">
      <form className=" " onSubmit={handleSubmit}>
        <div className="my-2 ">
          {!currentUserData.formData && (
            <div className="flex flex-col justify-center text-center w-[110%] mb-8">
              <div className="font-bold mb-2 text-greenPrairi">
                To match with investors, now create your Data Profile...
              </div>
              <div>
                This info is only seen by an investor if you match what they are
                looking for (or is privately shared, by them, with another
                investor).
              </div>
              <div className="flex justify-center items-center mt-4 ">
                <img
                  alt="Clock"
                  src={Clock}
                  className="w-[50px] pr-4 brightness-50"
                />
                <div className="text-[#808080] font-bold">
                  Please complete your profile within 24 hours
                </div>
              </div>
            </div>
          )}
          <div className="flex items-end pl-3 md:pl-0">
            <div
              className={` h-10 md:h-12 flex items-center justify-center mr-2 md:mr-4 `}
            >
              <img className=" h-full  " src={CompanyLogo} alt="Company" />
            </div>
            <h1 className=" text-base  md:text-xl text-white uppercase font-bold">
              Company
            </h1>
          </div>
          <div className="mt-5 px-3 md:pl-24">
            <label htmlFor="company">Company Name</label>
            <input
              className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
              type="text"
              name="company"
              id="company"
              placeholder="Enter Company's name"
              value={company}
              onChange={handleChange}
            ></input>
          </div>
          <div className="mt-5 px-3 md:pl-24">
            <label htmlFor="url">Company URL (Website)</label>
            <input
              className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
              type="text"
              name="url"
              id="url"
              placeholder="Enter Company's website"
              value={url}
              onChange={handleChange}
            ></input>
          </div>

          <div className="mt-5 px-3 md:pl-24">
            <label htmlFor="country">Company Location</label>
            <select
              className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
              value={country}
              onChange={handleChange}
              name="country"
              id="country"
            >
              <option value="">Select a country</option>
              {countryList.map((option, index) => (
                <option key={option.value}>{option}</option>
              ))}
            </select>
          </div>
          {country === "United States" && (
            <div className="mt-5 px-3 md:pl-24">
              <label htmlFor="country">State</label>
              <select
                className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
                value={state}
                onChange={handleChange}
                name="state"
                id="state"
              >
                <option value="">Select a state</option>
                {usStates.map((option, index) => (
                  <option key={option.value}>{option}</option>
                ))}
              </select>
            </div>
          )}
        </div>
        <div className="my-5 ">
          <div className="flex items-end pl-3 md:pl-0">
            <div
              className={` h-10 md:h-12 flex items-center justify-center mr-2 md:mr-4 `}
            >
              <img className=" h-full  " src={PitchLogo} alt="Company" />
            </div>
            <h1 className=" text-base  md:text-xl text-white uppercase font-bold">
              Pitch Summary
            </h1>
          </div>
          <div className="mt-3 px-3 md:pl-24">
            <label htmlFor="slogan">Slogan</label>
            <input
              className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
              type="text"
              name="slogan"
              id="slogan"
              placeholder="Your one-liner"
              value={slogan}
              onChange={handleChange}
            ></input>
          </div>
          <div className="mt-5 px-3 md:pl-24">
            <label htmlFor="pitch">Pitch</label>
            <textarea
              className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
              name="pitch"
              id="pitch"
              placeholder="Elevator pitch: Make sure to include the problem you are solving, how you will solve it, and how you will grow, etc"
              value={pitch}
              onChange={handleChange}
              rows="3"
              maxLength={500}
            ></textarea>
            {limitMessage && <p className="text-redPrairi">{limitMessage}</p>}
          </div>

          <div className="mt-5 px-3 md:pl-24">
            <div>
              <label htmlFor="deck">Pitch deck</label>

              {
                <input
                  className="border-blackish mt-2.5 w-full bg-silverPrairi rounded border p-2.5 text-lighterGray outline-none"
                  type="text"
                  value={
                    fileName === ""
                      ? globalData.pendingPitchDeck && !globalData.pitchdeck
                        ? globalData.pendingPitchDeck
                        : deck
                      : fileName
                  }
                ></input>
              }

              {!globalData.pitchdeck && deck === "" && (
                <div>
                  <input
                    type="file"
                    name="pitchdeck"
                    id="pitchdeck"
                    accept="application/pdf"
                    onChange={handleFileChange}
                    className="hidden"
                  />
                  <div className="flex items-baseline">
                    <label
                      htmlFor="pitchdeck"
                      className="px-4 py-2 my-4 bg-[#3A3A3A] justify-end text-white w-1/4 text-center rounded cursor-pointer hover:text-silverPrairi"
                    >
                      Choose file
                    </label>

                    <p className="ml-4 italic text-greenPrairi truncate w-[70%]">
                      {fileName !== "" && <>Successfully uploaded!</>}
                    </p>
                  </div>
                </div>
              )}
              {pendingDeck && deck !== "" && (
                <div className="text-yellowPrairi font-bold">
                  [New pitch deck pending approval]
                </div>
              )}
              {testUrl(deck) && (
                <p
                  className="text-xs underline cursor-pointer"
                  onClick={() => setPitchUpdatePopup(true)}
                >
                  Need to update?
                </p>
              )}
              <Modal open={pitchUpdatePopup} border="grey" size="xl">
                <div className="p-2 text-justify text-sm">
                  <div className="flex justify-end  text-sm  mb-4 ">
                    <TextButton
                      color="silver"
                      click={() => setPitchUpdatePopup(false)}
                      label="Close"
                    />
                  </div>
                  <p className="mb-4">
                    If you want to update your pitch deck, our team needs to
                    review it first to ensure quality. We will notify you as
                    soon as the new deck is approved. In the meantime, your
                    previous deck will remain visible to investors.
                  </p>
                  <input
                    type="file"
                    name="pitchdeck"
                    id="pitchdeck"
                    accept="application/pdf"
                    onChange={handleFileChange}
                    className="hidden"
                  />
                  <div className="flex items-baseline">
                    <label
                      htmlFor="pitchdeck"
                      className="px-4 py-2 my-4 bg-[#3A3A3A] text-white w-1/3 text-center rounded cursor-pointer hover:text-silverPrairi"
                    >
                      Choose file
                    </label>

                    <p className="ml-4 italic text-silverPrairi truncate w-[70%]">
                      {fileName}
                    </p>
                  </div>
                  {uploadingDeck && (
                    <div className="text-greenPrairi text-xs">
                      Submitting...
                    </div>
                  )}
                  <div className="flex justify-center  ">
                    <PrairiButton
                      label="Submit"
                      border="silver"
                      click={handleSubmitDeck}
                      size="sm"
                      rounded="soft"
                    />
                  </div>
                </div>
              </Modal>
            </div>
          </div>

          <div className="mt-3 px-3 md:pl-24">
            <label htmlFor="slogan">
              Link to a <span className="font-bold">video</span> pitch
              [optional]
            </label>
            <input
              className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
              type="text"
              name="videoPitch"
              id="videoPitch"
              placeholder=""
              value={videoPitch}
              onChange={handleChange}
            ></input>
          </div>

          <div className="mt-5 px-3 md:pl-24">
            <div className="flex justify-between items-center my-2 ">
              <h1 className="mr-4">
                Company's social media profiles [optional]
              </h1>
            </div>
            {/* dynamic fields */}
            {generatedFields.profile.map((field, index) => {
              return (
                <div
                  key={field.fieldName}
                  className="flex justify-between items-center "
                >
                  <input
                    className="border-blackish my-2.5 w-10/12  rounded border p-2.5 text-black outline-none"
                    type="text"
                    name={field.fieldName}
                    id={field.fieldName}
                    placeholder={`https://www.profile${index + 1}.com/username`}
                    value={field.fieldValue}
                    onChange={(e) => handleDynamicInput(e, "profile", index)}
                  ></input>
                  {index === 0 && (
                    <input
                      type="button"
                      className="  uppercase bg-navBg cursor-pointer rounded border border-gray-500 flex items-center justify-center w-10 h-10  text-base text-white outline-none transition-all duration-300 ease-in-out hover:scale-105 "
                      value="+"
                      onClick={() => addField("profile")}
                    ></input>
                  )}
                  {index > 0 && (
                    <input
                      type="button"
                      className="  uppercase bg-navBg cursor-pointer rounded border border-gray-500 flex items-center justify-center w-10 h-10 text-base  text-white outline-none transition-all duration-300 ease-in-out hover:scale-105 "
                      value="-"
                      onClick={() => closeField("profile", index)}
                    ></input>
                  )}
                </div>
              );
            })}
          </div>
          <div className="mt-5 px-3 md:pl-24">
            <div className="flex justify-between items-center my-2 ">
              <h1 className="mr-4">
                Other information links, such as press, media, etc. [optional]
              </h1>
            </div>
            {/* dynamic fields */}
            {generatedFields.resource.map((field, index) => {
              return (
                <div
                  key={field.fieldName}
                  className="flex justify-between items-center "
                >
                  <input
                    className="border-blackish my-2.5 w-10/12 mr-auto rounded border p-2 text-black outline-none"
                    type="text"
                    name={field.fieldName}
                    id={field.fieldName}
                    placeholder={`https://www.resource${index + 1}.com`}
                    value={field.fieldValue}
                    onChange={(e) => handleDynamicInput(e, "resource", index)}
                  ></input>
                  {index === 0 && (
                    <input
                      type="button"
                      className="  uppercase bg-navBg cursor-pointer rounded border border-gray-500 flex items-center justify-center w-10 h-10 text-base text-white outline-none transition-all duration-300 ease-in-out hover:scale-105 "
                      value="+"
                      onClick={() => addField("resource")}
                    ></input>
                  )}
                  {index > 0 && (
                    <input
                      type="button"
                      className="  uppercase bg-navBg cursor-pointer rounded border border-gray-500 flex items-center justify-center w-10 h-10 text-base text-white outline-none transition-all duration-300 ease-in-out hover:scale-105 "
                      value="-"
                      onClick={() => closeField("resource", index)}
                    ></input>
                  )}
                </div>
              );
            })}
          </div>

          <div className=" pl-3 md:pl-24 my-4">
            <p>
              Is your startup focused on impacting any specific Sustainable
              Development Goal?
            </p>
            <div className=" flex items-center justify-center ">
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="goals"
                  name="goals"
                  onChange={handleGoalsBoolean}
                  value={showGoals}
                  row
                >
                  <FormControlLabel
                    color="success"
                    value="Yes"
                    control={<WhiteRadio checked={goalsBool === "Yes"} />}
                    label={<span className="mr-10">Yes</span>}
                  />
                  <FormControlLabel
                    value="No"
                    control={<WhiteRadio checked={goalsBool === "No"} />}
                    label={<span className="">No</span>}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          {goalsBool === "Yes" && (
            <div className=" pl-3 md:pl-24 my-4">
              <p className="mb-2">Select one or more from the following:</p>
              <div className="grid grid-cols-4 grid-rows-5 gap-4">
                <ImageGridItem
                  src={NoPoverty}
                  click={() => goalsHandler("No Poverty")}
                  value="No Poverty"
                  selected={goals.includes("No Poverty")}
                />
                <ImageGridItem
                  src={ZeroHunger}
                  click={() => goalsHandler("Zero Hunger")}
                  value="Zero Hunger"
                  selected={goals.includes("Zero Hunger")}
                />
                <ImageGridItem
                  src={GoodHealth}
                  click={() => goalsHandler("Good Health and Well-being")}
                  value="Good Health and Well-being"
                  selected={goals.includes("Good Health and Well-being")}
                />
                <ImageGridItem
                  src={QualityEducation}
                  click={() => goalsHandler("Quality Education")}
                  value="Quality Education"
                  selected={goals.includes("Quality Education")}
                />
                <ImageGridItem
                  src={GenderEquality}
                  click={() => goalsHandler("Gender Equality")}
                  value="Gender Equality"
                  selected={goals.includes("Gender Equality")}
                />
                <ImageGridItem
                  src={CleanWater}
                  click={() => goalsHandler("Clean Water and Sanitation")}
                  value="Clean Water and Sanitation"
                  selected={goals.includes("Clean Water and Sanitation")}
                />
                <ImageGridItem
                  src={CleanEnergy}
                  click={() => goalsHandler("Affordable and Clean Energy")}
                  value="Affordable and Clean Energy"
                  selected={goals.includes("Affordable and Clean Energy")}
                />
                <ImageGridItem
                  src={DecentWork}
                  click={() => goalsHandler("Decent Work and Economic Growth")}
                  value="Decent Work and Economic Growth"
                  selected={goals.includes("Decent Work and Economic Growth")}
                />
                <ImageGridItem
                  src={Innovation}
                  click={() =>
                    goalsHandler("Industry, Innovation and Infrastructure")
                  }
                  value="Industry, Innovation and Infrastructure"
                  selected={goals.includes(
                    "Industry, Innovation and Infrastructure"
                  )}
                />
                <ImageGridItem
                  src={ReducedInequalities}
                  click={() => goalsHandler("Reduced Inequalities")}
                  value="Reduced Inequalities"
                  selected={goals.includes("Reduced Inequalities")}
                />
                <ImageGridItem
                  src={SustainableCities}
                  click={() =>
                    goalsHandler("Sustainable Cities and Communities")
                  }
                  value="Sustainable Cities and Communities"
                  selected={goals.includes(
                    "Sustainable Cities and Communities"
                  )}
                />
                <ImageGridItem
                  src={ResponsibleConsumption}
                  click={() =>
                    goalsHandler("Responsible Consumption and Production")
                  }
                  value="Responsible Consumption and Production"
                  selected={goals.includes(
                    "Responsible Consumption and Production"
                  )}
                />
                <ImageGridItem
                  src={ClimateAction}
                  click={() => goalsHandler("Climate Action")}
                  value="Climate Action"
                  selected={goals.includes("Climate Action")}
                />
                <ImageGridItem
                  src={LifeBelowWater}
                  click={() => goalsHandler("Life Below Water")}
                  value="Life Below Water"
                  selected={goals.includes("Life Below Water")}
                />
                <ImageGridItem
                  src={LifeOnLand}
                  click={() => goalsHandler("Life on Land")}
                  value="Life on Land"
                  selected={goals.includes("Life on Land")}
                />
                <ImageGridItem
                  src={Peace}
                  click={() =>
                    goalsHandler("Peace, Justice and Strong Institutions")
                  }
                  value="Peace, Justice and Strong Institutions"
                  selected={goals.includes(
                    "Peace, Justice and Strong Institutions"
                  )}
                />
                <ImageGridItem
                  src={Partnerships}
                  click={() => goalsHandler("Partnership for The Goals")}
                  value="Partnership for The Goals"
                  selected={goals.includes("Partnership for The Goals")}
                />

                <img src={SDG} className="col-span-3"></img>
              </div>
            </div>
          )}
        </div>
        <div className="mb-4 mt-8 ">
          <h1 className=" text-base pl-3 md:pl-24 md:text-xl text-white uppercase font-bold">
            Investors
          </h1>
          <div className="mt-5 px-3 md:pl-24 flex items-center">
            <span className="mr-5">Are there existing investors?</span>
            <Switch
              onChange={() =>
                setData({
                  ...data,
                  investorButton: !investorButton,
                })
              }
              checked={investorButton}
            />
          </div>
          <div className="mt-5 px-3 md:pl-24">
            <div className="flex justify-between items-center my-2 ">
              <h1>Investor [optional]</h1>
            </div>
            {/* dynamic fields */}
            {generatedFields.investor.map((field, index) => {
              return (
                <div
                  key={field.fieldName}
                  className="flex justify-between items-center "
                >
                  <input
                    className="border-blackish my-2.5 w-10/12 mr-auto rounded border p-2 text-black outline-none"
                    type="text"
                    name={field.fieldName}
                    id={field.fieldName}
                    placeholder={`Investor ${index + 1}`}
                    value={field.fieldValue}
                    onChange={(e) => handleDynamicInput(e, "investor", index)}
                  ></input>
                  {index === 0 && (
                    <input
                      type="button"
                      className="  uppercase bg-navBg cursor-pointer rounded border border-gray-500 flex items-center justify-center w-10 h-10 text-base text-white outline-none transition-all duration-300 ease-in-out hover:scale-105 "
                      value="+"
                      onClick={() => addField("investor")}
                    ></input>
                  )}
                  {index > 0 && (
                    <input
                      type="button"
                      className="  uppercase bg-navBg cursor-pointer rounded border border-gray-500 flex items-center justify-center w-10 h-10 text-base text-white outline-none transition-all duration-300 ease-in-out hover:scale-105 "
                      value="-"
                      onClick={() => closeField("investor", index)}
                    ></input>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {error && <p className="my-2 px-3 md:pl-24 text-red-500">{error}</p>}
        {message && (
          <p className="my-2 px-3 md:pl-24 text-green-500">
            Saving...{" "}
            <span className="text-silverPrairi">
              (click progress bar if you wish to edit)
            </span>
          </p>
        )}
        <div className="flex justify-center my-5 px-3 md:pl-29">
          <NextButton value="SAVE" />
        </div>
      </form>
    </div>
  );
};

export default Company;
